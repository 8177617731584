/* RecruitersPage.css */

/* Container Styles */
.rcontainer {
    background-color: #f2f2f2;
    /* height: 100vh; */
    padding: 30px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .header div {
    margin-bottom: 5px;
  }
  
  .header-title {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
  
  .header-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
  }
  
  /* Buttons Styles */
  /* .button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .button-group button {
    padding: 0 24px;
    gap: 8px;
    border-radius: 4px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .button-group .primary-button {
    width: 183px;
    height: 40px;
    background-color: #00adf0;
    color: #fafafa;
    border: 1px solid #00adf0;
    cursor: pointer;
  }
  
  .button-group .secondary-button {
    width: 112px;
    height: 40px;
    color: #00adf0;
    border: 1px solid #00adf0;
    cursor: pointer;
  } */
  
  /* Stats Cards Styles */
  .candidate-list-stats-cards {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 20px;
    /* padding-top: 20px; */
  }
  
  .candidate-list-stats-card {
    background-color: #ffffff;
    width: 100%;
    max-width: 230px;
    height: 70px;
    border: 1px solid #dae1e7;
    border-radius: 4px;
    display: flex;
    padding: 12px 16px;
    gap: 16px;
    /* align-items: center; */
  }
  
  .candidate-list-stats-card img {
    width: 26px;
    height: 26px;
  }
  
  .candidate-list-stats-right-content {
    padding-left: 10px;
  }
  
  .candidate-list-stats-card .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #726c6c;
  }
  
  .candidate-list-stats-card .value {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: #2b2829;
    padding-top: 10px;
  }
  
  /* Filter Section Styles */
  .filter-section {
    background-color: #ffffff;
    margin-top: 25px;
    padding: 16px 24px;
  }
  
  .filter-section .filter-bar {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    gap: 10px;
  }
  
  .filter-group {
    display: flex !important;
    flex: 3;
    height: auto;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .filter-bar .filter-input {
    width: 404px;
    height: 20px;
    padding: 10px 14px;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #ced7de;
  }
  
  .search-input {
    width: 404px;
    height: 40px;
  }
  
  .filter-bar .filter-select {
    width: 150px;
    height: 40px;
    /* padding: 10px 14px; */
    gap: 8px;
    border-radius: 4px;
    /* border: 1px solid #CED7DE; */
    color: #7991a4;
  }
  
  .filter-bar .filter-button {
    width: 125px;
    height: 40px;
    padding: 0 24px;
    border-radius: 4px;
    background-color: #dae1e7;
    border: none;
    cursor: pointer;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .filter-bar .filter-button img {
    margin-right: 8px;
  }
  
  .filter-bar .filter-button div {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  
  /* Table Styles */
  .table-container {
    padding-top: 20px;
  }
  
  .ant-modal-content-modify .ant-modal-content {
    /* width: 102vw !important; */
    width: 486px !important;
    height: 102vh !important;
    position: fixed !important;
    top: -7px !important;
    right: -7px !important;
    background-color: #ffffff !important;
    padding: 0 !important;
    padding-top: 20px !important;
    margin-bottom: 15px !important;
    /* padding-left: 15px !important; */
  }
  
  .ant-modal-content-modify .ant-modal-header {
    margin-bottom: 18px !important;
  }
  
  .recruiter-modal .ant-modal-body {
    height: 102vh !important;
  }
  
  @media (max-width: 767px) {
    .ant-modal-content-modify .ant-modal-content {
      width: 102vw !important;
    }
  
    .candidate-list-stats-cards {
      flex-wrap: wrap;
    }
  
    .search-input {
      width: 320px;
    }
  }
  
  @media (max-width: 576px) {
    .profile-footer {
      padding-top: 15px !important;
    }
  
    .profile-details-main {
      height: 65%;
    }
  }
  